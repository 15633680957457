import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { PhoneIcon } from "../../components/Icons"
import { Section, SectionTitle, SectionImageBkg } from "../../components/Section"

const PhoneNumber = styled.div`
    margin: 10px 0 0;
    display: inline-flex;
    .phone-number{
        font-family: 'Teko', sans-serif;
        font-weight: 700;
        color:#1383C1;
        display:flex;
        align-items: flex-start;
        font-size: 40px;
        line-height: 30px;
        @media (min-width: 576px) {
            font-size: 50px;
            line-height: 40px;
        }
        @media (min-width: 768px) {
            font-size: 60px;
            line-height: 50px;
        }
        @media (min-width: 992px) {
            font-size: 70px;
            line-height: 60px;
        }
        .icon{
            display:flex;
            align-items:center;
            justify-content:center;
            > svg{
                fill:#1383C1;
                height: 24px;
                width: 15px;
                @media (min-width: 576px) {
                    height: 32px;
                    width: 20px;
                }
                @media (min-width: 768px) {
                    height: 40px;
                    width: 25px;
                }
                @media (min-width: 992px) {
                    height: 50px;
                    width: 30px;
                }
            }
            + .text{
                margin-left:5px;
                @media (min-width: 768px) {
                    margin-left:10px;
                }
            }
        }
        &:hover, &:focus{
            color:#E08932;
            .icon{
                > svg{
                    fill:#E08932;
                }
            }
        }
    }
`

const CallUs = () => {
    return (
       
        <Section pt="120px" pb="120px" xpt="80px" xpb="80px" mpt="40px" mpb="40px" bgColor="#fff" className="section-help">	
            <SectionImageBkg  bgAfter="linear-gradient(to right,rgb(0,0,0) 0%,rgba(0,0,0,0) 100%)"	widthAfter="70%"><StaticImage src="../../images/help-bkg.jpg" alt="help-bkg" /></SectionImageBkg>
            <div className="container">
                <SectionTitle ml="0" mb="0" color="#fff">Questions? Call us at</SectionTitle>              
                <PhoneNumber>
                    <a href="tel:3869610006" aria-label="Phone" className="phone-number">
                        <span className="icon"><PhoneIcon /></span>
                        <span className="text">386-961-0006</span>
                    </a>
                </PhoneNumber>
            </div>
        </Section>
    )
}

export default CallUs
