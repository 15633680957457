import React, { useState } from "react"
import axios from 'axios';
import { navigate } from 'gatsby';
import DefaultButton from "../Button/DefaultButton"
import { Form, FormRow, FormCol, FormAction } from "../Form"

const ContactForm = ({ location }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault()
    if (name !== "" && email !== "" && phone !== "" && message !== "") {
      const data = {
        source: location.origin,
        form: "Contact form",
        page: location.pathname,
        first_name: name,
        email: email,
        phone_no: phone,
        comment: message,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/v1/create-lead",
        data: data,
        headers: { Authorization: "Bearer " + process.env.GATSBY_API_TOKEN }
      }).then((res) => {
          navigate("/thank-you");
        }).catch((error) => {
            alert(error)
        });
    }
  }
  return(
    <form onSubmit={handleSubmit}>
      <Form className="form">
        <FormRow className="form-row">
          <FormCol className="form-col">
            <div className="form-group">
              <input
                type="text" id="name"
                pattern="[A-Za-z\s]+$"
                className="form-control"
                placeholder="YOUR NAME"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          </FormCol>          
          <FormCol className="form-col">
            <div className="form-group">
              <input
                type="email"
                id="email"
                className="form-control"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="EMAIL ADDRESS"
                required
              />
            </div>
          </FormCol>
          <FormCol className="form-col">
            <div className="form-group">
              <input
                type="tel"
                id="phone_no"
                className="form-control"
                placeholder="PHONE NUMBER"
                onChange={(e) => setPhone(e.target.value)}
                pattern="[0-9]{10}"
                required
              />
            </div>
          </FormCol>
          <FormCol className="form-col">
            <div className="form-group form-group-textarea">
              <textarea
                type="text"
                id="message"
                className="form-control"
                placeholder="WRITE YOUR MESSAGE HERE..."
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
          </FormCol>
        </FormRow>
        <FormAction className="form-action">
          <button type="submit" aria-label="button"><DefaultButton text="Send Message" animate="ripple-white"  /></button>
        </FormAction>
      </Form>
    </form>
  )
}

export default ContactForm