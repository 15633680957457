import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import { Section, SectionDescription, SectionPageTitle, SectionImageBkg} from "../components/Section"
import {FormPrimary} from "../components/Form"
import CallUs from "../components/CallUs"
import ContactForm from "../components/ContactForm"
import BlueVector from "../images/blue-pattern.jpg"

const HeroBackground = styled.div`    
  display: flex;
  align-items: center;
  height: 100%;
  &:after, &:before{
  content: "";
  position: absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  }
  &:before{
    background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
  }
  &:after{
    background:${props => props.bgAfter};
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.15;
    background-position: center right;
  }	
`

const FormSection = styled(FormPrimary)`
  width:100%;
  margin:0 auto;
  @media (min-width: 768px) {
    max-width:730px;
  }
  @media (min-width: 1200px) {
    max-width:990px;
  }
  @media (min-width: 1440px) {
    max-width:1080px;
  }
  .form-action{
    justify-content: center;
  }
  .form-row{
    .form-col{
      &:nth-child(1), &:nth-child(2){
        @media (min-width: 768px) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
`

const FormTitle = styled.div`
	color:#fff;
    margin-bottom:20px; 
    font-weight:700;
    font-size:22px;
    line-height:32px; 
    text-align:center;
    @media (min-width: 1200px) {
      font-size: 28px;
      line-height: 38px;
    }
    @media (min-width: 1600px) {
      font-size: 32px;
      line-height: 42px;
    }
`


const BannerBackground = styled.div`    
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction:column;
  @media (min-width: 576px) {
    flex-direction:row;
    
  }
  &:after, &:before{
    content: "";
    position: absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    @media (min-width: 1200px) {
      clip-path: ellipse(calc(100% - 30px) calc(100% + 180px) at 100% 50%);
    }
  }
  &:before{
		background: transparent linear-gradient(270deg, #1383C1 0%, #006EAC 100%) 0% 0% no-repeat padding-box;		
	}
	&:after{
		background:${props => props.bgAfter};
		background-repeat: no-repeat;
		background-size: cover;
		opacity: 0.15;
		background-position: center right;
  }
`
const GridHero = styled.div`
  position: relative;
  @media (min-width: 992px) {
    display: grid;
    align-items:start;
    grid-template-columns:1fr 1.2fr;
  }
  @media (min-width: 1600px) {
    grid-template-columns:1.2fr 1fr;
  }
`

const GridHeroLeft = styled.div`
  position: relative;    
  padding: 30px 15px;
  @media (min-width: 992px) {
      padding: 40px 30px 40px 30px;  
  }
  @media (min-width: 1200px) {
      padding: 60px 30px 60px 30px;  
  }
  @media (min-width: 1600px) {
      padding: 90px 30px 90px 30px;  
  }
  @media (min-width: 1650px) {
      padding: 90px 30px 90px calc(50vw - 810px);  
  }
`
const GridHeroRight = styled.div`
  position: relative;    
  filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.4));    
  
  padding: 30px 15px;
  @media (min-width: 992px) {
      position: sticky;
      top: 64px;
      height: calc(100vh - 64px);
      padding: 30px;
  }
  @media (min-width: 1200px) {
      padding: 40px 30px 60px 30px;
      height: calc(100vh - 80px);
      top: 80px;
  }
  @media (min-width: 1600px) {
      padding: 120px 30px 170px 30px;
  }
  @media (min-width: 1650px) {
      padding: 120px calc(50vw - 810px) 170px 30px;
  }
`
const MapImage = styled.div`
  position:relative;
  z-index:1;
  overflow:hidden;
  text-align: right;
  @media (min-width: 992px) {
    position:absolute;
    right:0;
    bottom:0;
  }
  .gatsby-image-wrapper{
    margin-left:auto;
    margin-right:20px;    
    max-width: 90%;
    @media screen and ( max-height: 768px ){
      max-width: 70%;
    }
    @media (min-width: 1600px) {
      max-width: 100%;
    }
  }
`
const LeadText = styled.div`
  position:relative;
  z-index:2;
  width:100%;
  color:#fff;
  @media (min-width: 992px) {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    z-index:2;
    max-width:300px;
    right:220px;
  }
  @media (min-width: 1024px) {
    right:260px;
  }
  @media (min-width: 1200px) {
    max-width:320px;
  }
  @media (min-width: 1280px) {
    max-width:360px;
  }
  @media (min-width: 1440px) {
    max-width:400px;
  }
  p{
    font-size:16px;
    margin-bottom:20px;
    font-weight:500;
    font-style:italic;
    line-height:24px; 
    @media (min-width: 1200px) {
      line-height:28px; 

    }
  }
`
const LeadCheck = styled.div`
	display:flex;
  flex-wrap:wrap;
  align-items:center;
  font-weight:700;
  font-size:16px;
  line-height:26px;
  margin-bottom:30px;
  @media (min-width: 992px) {
    font-size:18px;
    line-height:28px;
  }
  @media (min-width: 1200px) {
    font-size:24px;
    line-height:30px;
  }
`


const CoverageLeadTimesPage = ({ location, data }) => {
  return(
    <Layout location={location}>
      <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
      />
      <GridHero>
        <SectionImageBkg opacity="0.3"><StaticImage src="../images/map-outerline.png" alt="map-outerline" /></SectionImageBkg>
          <GridHeroLeft>
            <SectionPageTitle className="h2" maxWidth="440px" ml="0">COVERAGE AREA AND LEAD TIMES</SectionPageTitle>
            <SectionDescription maxWidth="860px" ml="0" fontStyle="italic">
              <p>We service all of Florida with the exception of Monroe County (the Florida Keys) and we service Southern Georgia.</p>
              <p><strong>Why only an estimated time frame? That’s a fair question!</strong><br/>Due to the nature of the process, we are unable to schedule an exact date until 10-14 days prior to when your installation crew will show up on-site. We take pride in providing clear communication for our clients, and we stay in constant contact with our crews, shop team members, and scheduling staff. We rarely experience any scheduling delays outside of inclement weather or occasional factors which may occur outside of our control. However, since the requirements about permits, drawings (generic or wet stamps), leveled land are communicated very early during the order confirmation process, your building installation and delivery should be a smooth ride.</p>
            </SectionDescription>
          </GridHeroLeft>
          <GridHeroRight>
            <BannerBackground bgAfter={`url(${BlueVector})`}>
              <LeadText>
                <LeadCheck>
                  <StaticImage src="../images/check.png" alt="check" />
                  <strong>Lead time is 4-6 weeks</strong>
                </LeadCheck>
              </LeadText>
              <MapImage><StaticImage src="../images/coverage-area-hero.png" alt="coverage-area-hero" /></MapImage>
            </BannerBackground>
          </GridHeroRight>
        </GridHero>
      <CallUs />
      <Section pt="120px" pb="120px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="transparent" className="section-value">
        <HeroBackground bgAfter={`url(${BlueVector})`}>
          <div className="container">
            <FormSection>
              <FormTitle>Contact Scheduling Department</FormTitle>
              <ContactForm location={location} />
            </FormSection>
          </div>
        </HeroBackground>
      </Section>
    </Layout>
  )
}

export default CoverageLeadTimesPage;

export const pageQuery = graphql`
  query CoverageLeadTimesPageQuery{
    allContentfulPageData(filter: {page: {eq: "Coverage Area and Lead Time"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
  }
`

